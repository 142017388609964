@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";
$move-list-line-height: 46px;
.page-play {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
  .chessboard-wrp {
    width: 100%;
    .chessboard-tools {
      float: left;
      margin-top: 0;
      grid-template-columns: 2fr 2fr 1fr 1fr 1fr; //repeat(auto-fit, minmax(20px, 1fr));
      white-space: normal;
      border-bottom-right-radius: $border-radius-2;
      border-bottom-left-radius: $border-radius-2;
      box-shadow: $box-shadow-22;
      &.no-hint-btn {
        grid-template-columns: 2fr 2fr 1fr 1fr;
      }
      button.first {
        border-top-left-radius: unset;
        border-bottom-left-radius: $border-radius-2;
      }
      button.last {
        border-top-right-radius: unset;
        border-bottom-right-radius: $border-radius-2;
      }
    }
    .chessboard {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .info-wrp {
    margin-top: 6px;
    @include respond-to("lg") {
      margin-top: 0;
    }
    .move-list {
      height: $move-list-line-height * 3 + 42px;
      margin-bottom: 20px;
      .move-list-rounds {
        height: $move-list-line-height * 3;
      }
      @include respond-to("sm") {
        height: $move-list-line-height * 6 + 56px;
        margin-bottom: 30px;
        .move-list-rounds {
          height: $move-list-line-height * 6;
        }
      }
      @include respond-to("lg") {
        margin-bottom: 0;
      }
    }
  }
  @media screen and (orientation: landscape) {
    @include respond-to("lg") {
      margin-top: 5px;
      grid-template-columns: calc(100vh - 145px) auto;
      grid-gap: 20px;
      .player-panel {
        margin-top: 10px;
      }
      .move-analyser,
      .move-list {
        margin-top: 8px;
        margin-bottom: 0;
      }
      .info-wrp {
        .move-list {
          height: calc(100vh - 360px);
          .move-list-rounds {
            height: calc(100vh - 412px);
          }
        }
      }
    }

    @include respond-to("xl") {
      grid-template-columns: calc(100vh - 135px) auto;
      grid-gap: 30px;
      .player-panel {
        margin-top: 0;
      }
      .info-wrp {
        .choose-btn-wrp,
        .move-analyser {
          margin-top: 16px;
        }
        .move-list {
          height: calc(100vh - 350px);
          .move-list-rounds {
            height: calc(100vh - 400px);
          }
        }
      }
    }
    @include respond-to("xxl") {
      margin-top: 0;
      grid-template-columns: calc(100vh - 125px) auto;
      grid-gap: 30px;
      .player-panel {
        margin-top: 0;
      }
      .info-wrp {
        .choose-btn-wrp,
        .move-analyser {
          margin-top: 16px;
        }
        .move-list {
          height: calc(100vh - 358px);
          .move-list-rounds {
            height: calc(100vh - 412px);
          }
        }
      }
    }
  }
}
