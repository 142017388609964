@import "./styles/global.scss";
@import "./styles/buttons.scss";
@import "./styles/mixins.scss";
@import "./styles/vars.scss";
@import "./styles/vendors/balloon.scss";
/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./assets/fonts/rubik-v14-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./assets/fonts/rubik-v14-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
/* rubik-700 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('./assets/fonts/rubik-v14-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./assets/fonts/rubik-v14-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}
body {
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  font-size: 16px;
  color: $color;
  font-weight:400;
  background-color: $background-color;
  @include respond-to("lg") {
    overflow-y: overlay;
  }
  
}
iframe {
  position: fixed;
  top: 50%;
  transform: translate(10%, -50%);
}
input[type="email"],
input[type="text"],
select,
textarea {
  width: 100%;
  min-height: 26px;
  line-height: 14px;
  font-size: 14px;
  padding: 8px 12px 4px;
  font-weight: 600;
  border: 2px solid #{lighten($color, 60%)};
  background-color: $input-bg;
  border-radius: $border-radius-2;
  -webkit-appearance: none;
  box-shadow: inset 1px 2px 1px rgba(0, 0, 0, 0.25);
  background-color: $form-background-input-color;
  color: darken($color, 5%);
}
input[type="text"].sm,
input[type="email"].sm {
  min-height: 30px;
  padding: 4px 7px;
  font-size: 10px;
  line-height: 12px;
  border-radius: $border-radius-1;
}
input[type="email"]:focus,
input[type="text"]:focus, 
textarea:focus {
  border-color: darken($highlight-color-4, 20%);
  background-color: $highlight-color-4;
}
input[type="text"].error {
  color: $error-color !important;
  border-color: $error-color !important;
  background-color: $error-color-bg !important;
}
select {
  position:relative;
  padding:1px 50px 0 12px;  
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#{424b5c}' d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'/%3E%3C/svg%3E");
  background-position: calc(100% - 10px) 50%;
  background-size: 12px;
  background-repeat: no-repeat;  
  box-shadow:none;
  border:none; 
  margin-bottom: 2px; 
  line-height: 18px;  
  border: none;
  outline: none;
  scroll-behavior: smooth;
  box-shadow: 0 2px 0 0 darken($form-background-input-color, 16%), 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 2px 1px rgba(0, 0, 0, 0.15);
}
select option{   
  background: ligten($highlight-color-4, 20%);  
  font-weight: 700; 
}
select optgroup { 
  font-weight: 700; 
  option{    
    font-weight: 400; 
    font-family:'Lucida Console', monospace;
  }
}

 