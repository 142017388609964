@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";
@import "../../styles/icons.scss";
// nav-bar height
$nav-bar-height: 42px;
.nav-bar {
  top: 0;
  left: 0;
  position: relative;
  z-index: 99;
  width: 100%;
  height: $nav-bar-height;
  color: $color;
  text-align: center;
  padding: 5px 1.6%;
  @include respond-to("xs") {
    background: linear-gradient(
      darken($background-color, 17%) 50%,
      $background-color 170%
    );
  }
  @include respond-to("sm") {
    padding: 8px 1.6%;
  }
  @include respond-to("xxl") {
    padding: 0 0 0 3.4%;
    position: fixed;
    width: 18%;
    height: 100%;
    padding: 0 1vh;
    text-align: left;
    background: linear-gradient(
      darken($background-color, 12%) 10%,
      $background-color-2 100%
    );
  }

  .logoIcon {
    display: inline-block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,#{$logoIcon}");
    width: 22px;
    height: 22px;
    margin-top: 4px;
    margin-right: 5px;
    vertical-align: top;
    @include respond-to("sm") {
      width: 22px;
      height: 22px;
      margin-top: 8px;
      margin-right: 5px;
    }
    @include respond-to("xxl") {
      margin: 6vh calc(50% - 28px) 0 calc(50% - 26px);
      width: 45px;
      height: 45px;
    }
  }
  .logo {
    display: inline-block;
    height: 38px;
    width: 118px;
    margin-right: -36px;
    background-position: 50% 32%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,#{$logo}");
    @include respond-to("sm") {
      background-position: 50% 40%;
      height: 42px;
      width: 120px;
      margin-right: -26px;
    }
    @include respond-to("xxl") {
      margin: 0 20% 4vh 20%;
      width: 60%;
      background-position: 50% 55%;
    }
  }
}

.nav-bar {
  button.burger-menu-btn {
    position: relative;
    float: right;
    background: none;
    width: 30px;
    height: 32px;
    margin: 3px 6px 0 0;
    border: none;
    cursor: pointer;
    box-shadow: none;
    @include respond-to("sm") {
      width: 30px;
      height: 38px;
      margin: 3px 0 0 0;
    }
    @include respond-to("xxl") {
      display: none;
    }
    &:before {
      content: "";
      position: absolute;
      left: 10%;
      top: 50%;
      margin-top: -0.6em;
      width: 80%;
      height: 0.22em;
      background: darken($color, 6%);
      box-shadow: 0 0.35em 0 0 darken($color, 6%),
        0 0.7em 0 0 darken($color, 6%);
    }
  }
  ul.burger-menu-items {
    position: absolute;
    left: 0;
    top: $nav-bar-height;
    text-align: left;
    width: 100%;
    overflow: hidden;
    backdrop-filter: blur(4px);
    max-height: 0;
    transition: max-height 0.3s;
    background: linear-gradient(
      rgba(darken($background-color, 12.5%), 1) 40%,
      rgba($background-color, 0.7) 100%
    );
    @include respond-to("xxl") {
      max-height: 1000px;
      position: relative;
      left: auto;
      top: auto;
      background: none;
      border: none;
      overflow: visible;
    }
    li {
      height: 36px;
      width: 100%;
      margin: 10px 20px 0 2%;
      display: flex;
      @include respond-to("lg") {
        margin: 10px 20px 0 0;
      }
      @include respond-to("xxl") {
        margin: 10px 20px 0 0;
        height: 40px;
      }
      &:last-of-type {
        margin-bottom: 3vh;
      }
      a,
      button {
        display: flex;
        align-items: left;
        justify-content: left;
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        width: 100%;
        color: lighten($color, 12%);
        background: none;
        @include respond-to("xxl") {
          font-size: 19px;
        }
        [class^="icon"] {
          flex-shrink: 0;
          float: left;
          width: 32px;
          height: 32px;
          transition: background-color 0.3s ease;
          background-color: $color; // $primary-color-1;
          background-size: 46%;
          background-position: 50% 46%;
          float: left;
          margin: 0 10px 0 10px;
          background-color: lighten($color, 10%);
          border-radius: 100%;
        }
        &.is-active,
        &:hover {
          color: darken($color, 10%);
          [class^="icon"] {
            background-color: $highlight-color-5;
          }
        }
      }
    }
  }

  &.close ul.burger-menu-items {
    transition: max-height 600ms;
    max-height: 0;
    box-shadow: $box-shadow-32;
  }
  &.open ul.burger-menu-items {
    max-height: 1000px;
    box-shadow: $box-shadow-32;
    @include respond-to("xxl") {
      box-shadow: none;
    }
  }
}
