@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.privacy-policy {
  padding: 2vh 1vh;
  @include respond-to("md") {
    max-width: 800px;
    margin: 0 auto;
  }
  .card {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(lighten($background-color, 2%)),
      to($background-color-2)
    );
    h1 {
      font-size: 42px;
      padding: 10px 0;
    }
  }
}
