.piece { 
  margin: 0;
  width: 100%;
  height: 100%;
  display: inline-block; 
  svg{
    width: 100%;
    height: 100%; 
    vertical-align: baseline; 
    .pathPiece{
      fill:#38424b;  
      stroke:rgba(0,0,0,.4); 
      stroke-linejoin: round;
      stroke-linecap: round; 
    } 
     
  }
}
.piece[color="b"] {  
  .pathPiece{ 
    fill:#2a2f31;    
    stroke:rgba(0,0,0,.2);   
  }  
  .pathLine{ 
    fill:#3e4349e7;   
  }
}
.piece[color="w"] {  
  .pathPiece{ 
    fill:rgba(255,255,255, 1);  
    stroke:rgba(0,0,0,.5);    
  }  
  .pathLine{ 
    fill:#4b5159e7;   
  }
}

/*
Inline
*/
.piece.inline {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  svg{
    float:left;
  }
} 
