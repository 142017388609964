@import "../styles/bootstrap5Reboot.scss";
@import "../styles/vars.scss";
@import "../styles/mixins.scss";
@import "../styles/icons.scss";
@import "../styles/themesPage.scss";
@import "../styles/themesBoardsAndPieces.scss";

// required bootstrap files
//@import "node_modules/bootstrap/scss/functions";
//@import "node_modules/bootstrap/scss/variables";
// Override variable defaults
//
// Here is where we customize the variables from Bootstrap's `variables.scss`.
// We copy-pasta variables we need from that source file, remove the `!default`
// flag, and finally change their values.
//@import "node_modules/bootstrap/scss/mixins";

// @import "node_modules/bootstrap/scss/root";
//@import "node_modules/bootstrap/scss/reboot";
//@import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
//@import "node_modules/bootstrap/scss/grid";
// @import "node_modules/bootstrap/scss/tables";
// @import "node_modules/bootstrap/scss/forms";
// @import "node_modules/bootstrap/scss/buttons";
// @import "node_modules/bootstrap/scss/transitions";
// @import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/button-group";
// @import "node_modules/bootstrap/scss/input-group";
// @import "node_modules/bootstrap/scss/custom-forms";
// @import "node_modules/bootstrap/scss/nav";
// @import "node_modules/bootstrap/scss/navbar";
// @import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/jumbotron";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/close";
// @import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/carousel";
//@import "node_modules/bootstrap/scss/utilities";
// @import "node_modules/bootstrap/scss/print";

html,
body {
  overflow-x: hidden;
}
* {
  padding: 0;
  margin: 0;
}
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:focus {
  outline: 0 !important;
}
ul,
li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}
label,
input,
button,
textarea {
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  border-color: transparent;
}
a,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

ul.list {
  li {
    padding: 15px 20px;
    font-size: $list-font-size;
    font-weight: 700;
    display: grid;
    grid-template-columns: 15fr 5fr;
    grid-template-rows: auto;
    align-items: center;
    grid-gap: 10px;
    justify-content: end;
    position: relative;
    min-height: $list-line-height;
    @include respond-to("sm") {
      font-size: $list-font-size + 2px;
    }
    @include respond-to("lg") {
      font-size: $list-font-size + 3px;
    }
    @include respond-to("xxl") {
      font-size: $list-font-size + 4px;
    }
    &:nth-of-type(odd) {
      background-color: $background-color;
    }
    :first-of-type {
      text-align: left;
    }
    button.toggle-container {
      position: absolute;
      right: 2vh;
    }
  }
}
.monospace-font {
  font-family: "Courier New", Courier, monospace;
}
.nbr-l {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
.nbr-r {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
.uc-f {
  text-transform: lowercase;
}
.uc-f:first-letter {
  text-transform: uppercase;
}
.md-1 {
  box-shadow: $box-shadow-1;
}
.md-2 {
  box-shadow: $box-shadow-2;
}
.md-3 {
  box-shadow: $box-shadow-3;
}
.md-4 {
  box-shadow: $box-shadow-4;
}
.md-12 {
  box-shadow: $box-shadow-12;
}
.md-22 {
  box-shadow: $box-shadow-22;
}
.md-32 {
  box-shadow: $box-shadow-32;
}
.md-42 {
  box-shadow: $box-shadow-42;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.card {
  margin-bottom: 1.2%;
  background: #fff;
  padding: 6px;
  border-radius: $border-radius-2;
  @include respond-to("sm") {
    padding: 10px;
  }
  @include respond-to("md") {
    padding: 15px;
  }
  :is(h1, h2, h3, h4, h5, h6) {
    margin: 0 0 2.2% 0;
    padding: 0;
    line-height: 1;
    @include respond-to("md") {
      font-size: 120%;
    }
  }
  &.dyn-padding {
    padding: 2vh;
    border-radius: $border-radius-2;
    @include respond-to("sm") {
      padding: 3vh;
    }
    @include respond-to("md") {
      padding: 4vh;
    }
  }
}
.card-bg-2 {
  border: 6px solid #fff;
  padding: 0;
  background-color: $background-color;
  @include respond-to("md") {
    border: 10px solid #fff;
  }
}
.grid-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  grid-gap: 0;
}
.grid-group-gap-1 {
  grid-gap: 5px;
}
.grid-group-gap-2 {
  grid-gap: 10px;
}
.grid-row {
  padding: 0 2vh;
  height: 46px;
}
.grid-row:nth-of-type(odd) {
  background-color: $background-color;
}
.grid-row:nth-of-type(even) {
  background-color: #fff;
}
.empty-preloader:empty,
.preloader {
  background-image: url("data:image/svg+xml,#{$preloader}");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: auto;
  &.preloader-v2 {
    background-image: url("data:image/svg+xml,#{$preloader2}");
    background-size: 16vh;
  }
}

.info-box {
  .info-label {
    font-weight: 700;
    font-size: 8px;
    line-height: 14px;
    text-transform: uppercase;
    @include respond-to("xxl") {
      font-size: 9px;
    }
  }
  .info {
    background-color: $none-form-bg-color;
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
    padding: 0 1vh;
    border-radius: $border-radius-1;
    position: relative;
    color: $color;
    font-variant-numeric: tabular-nums;
    min-height: 20px;
    @include respond-to("sm") {
      line-height: 22px;
      font-size: 12px;
      min-height: 22px;
    }
    @include respond-to("xxl") {
      line-height: 28px;
      font-size: 16px;
      min-height: 28px;
    }
    .info-label {
      position: absolute;
      left: 0;
      bottom: -16px;
      font-size: 9px;
      @include respond-to("xxl") {
        bottom: -19px;
        font-size: 10px;
      }
    }
  }
}
