// colors
$color: #2f343f;
$color-light: rgb(235, 235, 235);
$background-color: #e5eef3;
$background-color-2: lighten(#eae6ff, 3%);
$color-muted: #7b7e85;
$disabled-color: #7b7e85;
$disabled-background-color: $background-color;
$none-form-bg-color: $background-color;
// form
$form-background-btn-color: #e3eaef;
$form-background-input-color: #e3eaef;
$form-background-select-color: #e3eaef;
// Icons/logo colors
$icons-color: lighten($color-light, 20%);
$icons-color-black: $color;
$logo-color: #2f3644;
// colors - Blue
$primary-color-1: #53758b; //#395c74,  #1a405b;
$primary-color-2: #133044;
$primary-color-3: #345166;
$primary-color-4: #8499a8;
// colors - Gray/Blue
$primary-color-5: #f5f7f7;
$primary-color-6: #f4f4f5;
$primary-color-7: #8499a8;
$primary-color-8: darken($background-color, 10%);
// Higlight
$highlight-color-1: #5fe0fd;
$highlight-color-2: #dbd82b;
$highlight-color-3: #68dd9f;
$highlight-color-4: #c6f3ec;
$highlight-color-5: #e66617;
// GameStat colors
$best-color: #267e32;
$good-color: $color-muted;
$mistake-color: #e66617;
$blunder-color: #ca0d0d;
$inaccuracy-color: lighten($mistake-color, 5%);
$bookmove-color: #916e55;
$not-found-color: $color-muted;
$analyzing-color: #9b9b9b;
$alternative-color: $color-muted;
/*theme colors*/
$theme-color-blue: $primary-color-1;
$theme-color-orange: $highlight-color-5;
$theme-color-green: $best-color;
// Error
$error-color: #bb3a3a;
$error-color-bg: #f5dede;
$success-color: #2d5031;
$success-color-bg: #c2e4c7;
// Form elements
$input-bg: #fff;
$shadow-color: lighten($color, 20%);
$input-shadow: inset 0 1px 3px rgba($shadow-color, 0.12),
  inset 0 1px 2px rgba($shadow-color, 0.24);
// border-radius
$border-radius-1: 2px;
$border-radius-2: 4px;
$border-radius-3: 6px;
// ul/li list
$list-background-color: lighten($primary-color-5, 3%);
$list-font-size: 14px;
$list-line-height: 40px;

// box shadows
$shadow-color: lighten($color, 20%);
$box-shadow-1: 0 2px 3px rgba($shadow-color, 0.12),
  0 1px 2px rgba($shadow-color, 0.24);
$box-shadow-2: 0 3px 6px rgba($shadow-color, 0.16),
  0 3px 6px rgba($shadow-color, 0.23);
$box-shadow-3: 0 10px 20px rgba($shadow-color, 0.19),
  0 6px 6px rgba($shadow-color, 0.23);
$box-shadow-4: 0 14px 28px rgba($shadow-color, 0.25),
  0 10px 10px rgba($shadow-color, 0.22);
$box-shadow-5: 0 19px 38px rgba($shadow-color, 0.3),
  0 15px 12px rgba($shadow-color, 0.22);
$shadow-color: #000;
$box-shadow-12: 0 2px 3px rgba($shadow-color, 0.12),
  0 1px 2px rgba($shadow-color, 0.24);
$box-shadow-22: 0 3px 6px rgba($shadow-color, 0.16),
  0 3px 6px rgba($shadow-color, 0.23);
$box-shadow-32: 0 10px 20px rgba($shadow-color, 0.19),
  0 6px 6px rgba($shadow-color, 0.23);
$box-shadow-42: 0 14px 28px rgba($shadow-color, 0.25),
  0 10px 10px rgba($shadow-color, 0.22);
$box-shadow-52: 0 19px 38px rgba($shadow-color, 0.3),
  0 15px 12px rgba($shadow-color, 0.22);
