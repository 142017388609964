@import "../../styles/vars.scss";

.privacy-policy-txt {
  ul {
    margin: 15px 15px 0 15px;
    li {
      margin-bottom: 15px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
