@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

/*board*/
$board-border-width: 5px;
$board-background: $primary-color-1;
/*notation*/
$board-notation-font-size: 7px;
/*squares*/
$square-darker-background: $board-background;
$square-lighter-background: lighten($board-background, 42%);
$square-active-color: $highlight-color-3;
$notation-active-color: #fff;
$notation-active-legal-color: $highlight-color-3;
$square-check-move-color: $blunder-color;
$square-lighter-legal-move-background: darken($highlight-color-3, 5%);
$square-darker-legal-move-background: $highlight-color-3;
$square-last-move-background: darken($highlight-color-2, 5%);
$square-engine-best-move-background: $highlight-color-1;
/*promotion*/
$promotion-background: darken($board-background, 4%);

.chessboard {
  height: auto;
  touch-action: none;
  box-shadow: $box-shadow-12;
  border-radius: $border-radius-2;
  background: $board-background;
  overflow: hidden;
  @include respond-to("md") {
    padding: $board-border-width / 2;
  }
}
.board-wrp {
  width: 100%;
  padding: $board-border-width;
  position: relative;
  .board {
    overflow: hidden;
    grid-area: board;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 0;
    width: 100%;
    box-sizing: border-box;
    outline: 0 solid transparent;
    .square {
      position: relative;
      padding-top: 100%;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      .piece {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        padding: 10% 12% 14% 12%;
        width: 100%;
        height: 100%;
        margin: 0;
        filter: drop-shadow(2px 1px 1px rgba(0, 0, 0, 0.15));
      }
    }

    .square.w {
      background: $square-lighter-background;
    }
    .square.check-move::before {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      display: block;
      background-color: transparent;
      border-radius: 100%;
      opacity: 0.6;
      animation: checkmove linear 0.6s infinite;
      animation-iteration-count: 2;
      animation-delay: 0.1s;
      width: 92%;
      height: 92%;
      margin-top: -46%;
      margin-left: -46%;
      box-shadow: inset 0 -3px 3px -3px rgba(0, 0, 0, 0.5);
    }
  }
}
.board-wrp.flipped .square.check-move::before {
  box-shadow: inset 0 3px 3px -3px rgba(0, 0, 0, 0.5);
}
.show-legal-moves.has-legal-moves .square.active::before,
.board-wrp .square.active::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  display: block;
  background-color: $square-active-color;
  border-radius: 100%;
  opacity: 0.6;
  animation: active linear 1s infinite 0.6s;
  box-shadow: inset 0 -3px 3px -3px rgba(0, 0, 0, 0.5);
  width: 92%;
  height: 92%;
  margin-top: -46%;
  margin-left: -46%;
  z-index: 1;
  pointer-events: none;
}
/*
.board-wrp .square.active::before {
  background-color: rgba(255,255,255,0.5); 
  animation:none;
}
.board-wrp .square.w.active::before {
  background-color: rgba(255,255,255,1); 
  animation:none;
}
*/
.show-legal-moves.has-legal-moves .square.active::after,
.board-wrp:not(.show-legal-moves) .square.active::after {
  display: none;
}
.show-legal-moves.has-legal-moves.flipped .square.active::before,
.board-wrp:not(.show-legal-moves).flipped .square.active::before {
  box-shadow: inset 0 3px 3px -3px rgba(0, 0, 0, 0.5);
}
@keyframes checkmove {
  0% {
    background-color: $square-check-move-color;
  }
  50% {
    background-color: lighten($square-check-move-color, 15%);
  }
  100% {
    background-color: $square-check-move-color;
  }
}
.board-wrp {
  .notation-numeric {
    pointer-events: none;
    grid-area: notation-numeric;
    display: flex;
    flex-wrap: wrap;
  }
  .notation-alpha {
    pointer-events: none;
    grid-area: notation-alpha;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 0;
    text-align: center;
  }
  .notation {
    text-transform: uppercase;
    font-weight: 700;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    color: $square-lighter-background;
    @media (hover: none), (hover: on-demand) {
      &.active {
        &.legal {
          color: $notation-active-legal-color !important;
        }
      }
    }
  }
}
.board-wrp.notation-hide {
  padding: $board-border-width;
}
.board-wrp.notation-inside {
  div[class^="notation-"] {
    position: absolute;
    z-index: 2;
  }
  .notation-numeric {
    top: $board-border-width;
    left: $board-border-width;
    height: calc(100% - #{$board-border-width * 2});
    align-items: initial;
  }

  .notation-alpha {
    left: $board-border-width;
    bottom: $board-border-width;
    width: calc(100% - #{$board-border-width * 2});
    text-align: right;
  }
  .notation {
    line-height: 0;
    font-size: $board-notation-font-size;
    @include respond-to("sm") {
      font-size: $board-notation-font-size * 1.4;
    }
    @include respond-to("lg") {
      font-size: $board-notation-font-size * 1.2;
    }
    @include respond-to("xl") {
      font-size: $board-notation-font-size * 1.6;
    }
  }
  .notation-numeric .notation:nth-child(odd),
  .notation-alpha .notation:nth-child(even) {
    color: lighten($color, 10%);
  }
  .notation-numeric .notation {
    flex: 1 0 100%;
    padding-top: $board-border-width + 2px;
    padding-left: 2px;
    @include respond-to("xl") {
      padding-top: $board-border-width + 5px;
    }
    overflow: hidden;
  }
  .notation-alpha .notation {
    width: 100%;
    padding-bottom: ($board-border-width + 2px);
    padding-right: $board-border-width - 2px;
    @include respond-to("xl") {
      padding-bottom: ($board-border-width + 4px);
    }
  }

  .notation-numeric .notation.active:nth-child(odd),
  .notation-alpha .notation.active:nth-child(even) {
    color: darken($color, 30%);
  }

  .notation-numeric .notation.active:nth-child(even),
  .notation-alpha .notation.active:nth-child(odd) {
    color: #fff;
  }
}
.board-wrp.notation-outside {
  position: relative;
  display: grid;
  grid-template-columns: min(14px) calc(100% - 14px); 
  @include respond-to("sm") {
    grid-template-columns: min(17px) calc(100% - 17px); 
  }
  @include respond-to("lg") {
    grid-template-columns: min(19px) calc(100% - 19px); 
  }
  @include respond-to("xl") {
    grid-template-columns: min(22px) calc(100% - 22px); 
  } 
  grid-template-rows: auto;
  grid-gap: 0;
  padding-left: 0;
  padding-bottom: 0;
  grid-template-areas:
    "notation-numeric board"
    ". notation-alpha";
  .board {
    -webkit-box-shadow: 0 0 1px 1px rgba(50, 50, 50, 0.15);
    -moz-box-shadow: 0 0 1px 1px rgba(50, 50, 50, 0.15);
    box-shadow: 0 0 1px 1px rgba(50, 50, 50, 0.15);
  }
  .notation-numeric {
    .notation {
      padding: 0 $board-border-width;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      padding-right: $board-border-width;
      -webkit-box-shadow: 0 -1px 1px rgba(50, 50, 50, 0.25);
      -moz-box-shadow: 0 -1px 1px rgba(50, 50, 50, 0.25);
      box-shadow: 0 -1px 1px rgba(50, 50, 50, 0.25);
      &:last-of-type {
        -webkit-box-shadow: 0 -1px 1px rgba(50, 50, 50, 0.25),
          0 1px 1px rgba(50, 50, 50, 0.25);
        -moz-box-shadow: 0 -1px 1px rgba(50, 50, 50, 0.25),
          0 1px 1px rgba(50, 50, 50, 0.25);
        box-shadow: 0 -1px 1px rgba(50, 50, 50, 0.25),
          0 1px 1px rgba(50, 50, 50, 0.25);
      }
    }
  }
  .notation-alpha {
    .notation {
      min-height: 14px; 
      @include respond-to("sm") {
        min-height: 19px;
      }
      @include respond-to("lg") {
        min-height: 20px;
      }
      @include respond-to("xl") {
        min-height: 22px;
      }
      padding: $board-border-width/2 0 0 0;
      -webkit-box-shadow: -1px 0 1px 0 rgba(50, 50, 50, 0.25);
      -moz-box-shadow: -1px 0 1px 0 rgba(50, 50, 50, 0.25);
      box-shadow: -1px 0 1px 0 rgba(50, 50, 50, 0.25);
      &:last-of-type {
        -webkit-box-shadow: -1px 0 1px 0 rgba(50, 50, 50, 0.25),
          1px 0 1px 0 rgba(50, 50, 50, 0.25);
        -moz-box-shadow: -1px 0 1px 0 rgba(50, 50, 50, 0.25),
          1px 0 1px 0 rgba(50, 50, 50, 0.25);
        box-shadow: -1px 0 1px 0 rgba(50, 50, 50, 0.25),
          1px 0 1px 0 rgba(50, 50, 50, 0.25);
      }
    }
  }
  .notation { 
    font-size: $board-notation-font-size;
    @include respond-to("sm") {
      font-size: $board-notation-font-size * 1.6;
    }
    @include respond-to("lg") {
      font-size: $board-notation-font-size * 1.8;
    }
    @include respond-to("xl") {
      font-size: $board-notation-font-size * 2;
    }
    &.active {
      color: #fff;
    }
  }
}

.board-wrp.no-moves .square:not(.active-for-promotion) {
  touch-action: none !important;
  pointer-events: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes active {
  0% {
    background-color: $square-active-color;
  }
  50% {
    background-color: lighten($square-active-color, 20%);
  }
  100% {
    background-color: $square-active-color;
  }
}
.square.is-dragged-over {
  @media (hover: none), (hover: on-demand) {
    .piece {
      pointer-events: none;
    }
    &:after {
      pointer-events: none;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      z-index: 10;
      width: 280%;
      height: 280%;
      margin-top: -93.333%;
      margin-left: -93.333%;
      border-radius: 100%;
      background: rgba(255, 255, 255, 0.3);
      box-shadow: 0 0 1px #fff; /* outer white */
    }
    &.legal-move:after {
      background: rgba($square-lighter-legal-move-background, 0.3);
      box-shadow: 0 0 1px #{$square-lighter-legal-move-background};
    }
  }
}

.square.b.is-dragged-over:after {
  border-color: rgba(255, 255, 255, 0.55);
}
.show-legal-moves .square.legal-move::before,
.board-wrp.show-legal-moves.allow-all-moves .square.is-dragged-over::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  display: block;
  border-radius: 100%;
  opacity: 0.6;
  box-shadow: inset 0 -3px 3px -3px rgba(0, 0, 0, 0.5);
}
.show-legal-moves.square.legal-move:hover::before,
.show-legal-moves.square.legal-move.is-dragged-over::before {
  opacity: 1;
}
/*
.square.legal-move.is-dragged-over {
  box-shadow: inset 0 0 0 3px $square-active-color;
  @include respond-to("sm") {
    box-shadow: none;
  }
}
*/
.show-legal-moves .square.legal-move:empty::before,
.board-wrp.show-legal-moves.allow-all-moves
  .square.is-dragged-over:not(.legal-move):empty::before {
  width: 26%;
  height: 26%;
  margin-top: -13%;
  margin-left: -13%;
}
.show-legal-moves .square.legal-move:not(empty)::before,
.show-legal-moves.board-wrp.allow-all-moves
  .square.is-dragged-over:not(.legal-move):not(empty)::before {
  width: 92%;
  height: 92%;
  margin-top: -46%;
  margin-left: -46%;
}
.show-legal-moves .square.w.legal-move::before {
  background-color: $square-lighter-legal-move-background;
}
.show-legal-moves .square.b.legal-move::before {
  background-color: $square-darker-legal-move-background;
}
.flipped .square::before {
  box-shadow: inset 0 3px 3px -3px rgba(0, 0, 0, 0.5);
}
.square.w.is-dragged-over.legal-move::before {
  background-color: lighten($square-lighter-legal-move-background, 14%);
}
.square.b.is-dragged-over.legal-move::before {
  background-color: lighten($square-darker-legal-move-background, 14%);
}
.board-wrp.allow-all-moves .square.w.is-dragged-over:not(.legal-move)::before {
  background-color: $square-active-color;
}
.board-wrp.allow-all-moves .square.b.is-dragged-over:not(.legal-move)::before {
  background-color: $square-active-color;
}
.square.last-move::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  content: "";
  display: block;
  border-radius: 100%;
  opacity: 0.8;
  box-shadow: inset 0 -3px 3px -3px rgba(0, 0, 0, 0.5);
  width: 26%;
  height: 26%;
  margin-top: -13%;
  margin-left: -13%;
}
.flipped .square.last-move::before {
  box-shadow: inset 0 3px 3px -3px rgba(0, 0, 0, 0.5);
}
.square.b.last-move::before {
  background: lighten($square-last-move-background, 10%);
}
.square.w.last-move::before {
  background: lighten($square-last-move-background, 5%);
}
.square.last-move:not(:empty)::before {
  width: 92%;
  height: 92%;
  margin-top: -46%;
  margin-left: -46%;
}
.square.move-hint::before {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  display: block;
  border-radius: 100%;
  opacity: 0.8;
  width: 26%;
  height: 26%;
  margin-top: -13%;
  margin-left: -13%;
  box-shadow: inset 0 -3px 3px -3px rgba(0, 0, 0, 0.5);
  background-color: lighten($square-engine-best-move-background, 2%) !important;
}
.flipped .square.move-hint::before {
  box-shadow: inset 0 3px 3px -3px rgba(0, 0, 0, 0.5);
}
.square.b.move-hint::before {
  background-color: darken($square-engine-best-move-background, 2%) !important;
}
.square.w.move-hint::before {
  background-color: lighten($square-engine-best-move-background, 2%) !important;
}
.square.move-hint:not(:empty)::before {
  width: 92%;
  height: 92%;
  margin-top: -46%;
  margin-left: -46%;
}
.piece[draggable="true"] {
  cursor: grab;
}
.piece[draggable="true"]:active {
  cursor: grabbing;
}

/* Promotion */
.promotion-picker {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 400%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 0;
  justify-content: center;
  align-items: center;
  padding: $board-border-width;
  background: $promotion-background;
  box-shadow: $box-shadow-32;
  border-radius: 2px;

  .promotion-piece-wrp {
    position: relative;
    padding-top: calc(100%);
    width: 100%;
    display: inline-block;
  }
  .promotion-piece-wrp::before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    margin-top: -50%;
    margin-left: -50%;
    border-radius: 100%;
    background-color: $square-active-color;
    border-radius: 100%;
    opacity: 0.9;
    animation: active linear 1s infinite 0.6s;
    box-shadow: inset 0 -3px 3px -3px rgba(0, 0, 0, 0.5);
  }
  .promotion-piece-wrp .piece {
    cursor: pointer;
  }
}
.promotion-picker.b {
  top: -300%;
}
.square.w.active-for-promotion::before {
  background-color: $square-lighter-background;
}
.square.b.active-for-promotion::before {
  background-color: $square-darker-background;
}
.square.hide-piece .piece,
.square.active-for-promotion > .piece {
  display: none;
}

.board-wrp.flipped {
  .board {
    transform: rotate(180deg);
  }
  .piece {
    transform: rotate(-180deg);
  }
  .square.active-for-promotion::before {
    transform: rotate(-180deg);
  }
  .modal-promotion-box {
    transform: rotate(-180deg);
  }
}
