@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.page-feedback { 
  @include respond-to("md") {
    max-width:800px;
    margin:0 auto; 
  } 
  @include respond-to("xxl") { 
    margin:0; 
  }

  .feedback-card {
    text-align: center;
    .hl {
      font-size: 40px;
      margin: 20px 0 0 0;
      padding: 0;
      text-align: center;
    }
    .manchet {
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      color: $color-muted;
      text-transform: uppercase;
      margin: 6px 0 15px 0;
    }
    .star-rate {
      margin: 30px auto;
    }
    .category {
      margin: 5px auto 0 auto;
    }
    .category {
      margin: 10px auto 0 auto;
    }
    .email,
    .description {
      margin-top: 5px;
      max-width: 100%;
    }
    .submit {
      margin-top: 5px;
    }
    .response {
      display: block;
      margin: 5px auto 0 auto;
      width: 100%;
      font-weight: 700;
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 4px;
      @include respond-to("xxl") {
        font-size: 22px;
        margin: 10px auto 0 auto;
      }
      &.errors {
        color: $error-color;
        background: $error-color-bg;
      }
      &.success {
        color: $success-color;
        background: $success-color-bg;
      }
    }
  }
}
