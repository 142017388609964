@import "../styles/vars.scss";

$fontColor: #e4e4e4;
$dark1: #3a3a39;
$dark0: lighten($dark1, 10%);
$dark2: darken($dark1, 5%);
$dark3: darken($dark2, 5%);
$dark35: darken($dark3, 3%);
$dark4: darken($dark3, 5%);
$dark5: darken($dark4, 5%);
$dark6: darken($dark5, 5%);

.user-settings-dark-mode {
  .modal-bg {
    background: linear-gradient(
      180deg,
      #{rgba($dark5, 0.6)} 0%,
      #{rgba($dark6, 0.9)} 100%
    ) !important;
    .modal {
      border-color: $dark3;
      background: $dark3;
      box-shadow: $box-shadow-52;
    }
  }
  color: $fontColor;
  ::-webkit-scrollbar-track {
    background-color: $dark4 !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $dark1 !important;
  }
  :hover {
    &::-webkit-scrollbar-thumb {
      background-color: $dark0 !important;
    }
  }
  .nav-bar {
    background: linear-gradient(#{$dark4}, 50%, #{rgba($dark1, 0.8)} 100%);
    @include respond-to("xxl") {
      background: $dark3;
    }
    .logo,
    .logoIcon {
      filter: brightness(12000%);
    }
    button.burger-menu-btn:before {
      background: $fontColor !important;
      box-shadow: 0 0.35em 0 0 #{$fontColor}, 0 0.7em 0 0 #{$fontColor};
    }
    ul.burger-menu-items {
      background: linear-gradient(
        #{rgba($dark1, 0.9)},
        70%,
        #{rgba($dark2, 1)} 1100%
      );
      li a,
      li button {
        color: $fontColor;
        &.is-active,
        &:hover {
          color: lighten($fontColor, 100%);
        }
      }
    }
  }

  .gradient {
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#{rgba($dark2, 0.94)}),
        to(#{rgba($dark3, 1)})
      )
      fixed;
  }
  .card {
    border-color: $dark3;
    background: $dark3;
    box-shadow: $box-shadow-12;
    .tag.active {
      color: lighten($fontColor, 16%) !important;
    }
  }

  .info-box {
    .info-label {
      color: darken($color-light, 36%) !important;
    }
    .info {
      background-color: $dark1 !important;
      color: darken($color-light, 19%) !important;
    }
  }
  .grid-row {
    background-color: $dark3 !important;
    &:nth-of-type(odd) {
      background-color: $dark2 !important;
    }
  }
  .game-over .grid-row,
  .list li {
    background-color: $dark3 !important;
    &:nth-of-type(odd) {
      background-color: $dark2 !important;
    }
  }
  .game-over-board-wrp .back {
    background-color: $dark4 !important;
  }
  .player-panel {
    [class*="player-"] .avatar {
      border-color: $dark3;
      box-shadow: $box-shadow-12;
    }
    .player-black .avatar {
      background-color: $dark4;
    }
    .winning-bar {
      box-shadow: $box-shadow-12;
      .black-winning-pct-bar {
        background-color: $dark4;
      }
    }
    .piece-advantage {
      color: $color-light !important;
    }
  }

  .move-analyser {
    &.good .user-move .feedback-move-type,
    &.not-found .user-move .feedback-move-type {
      color: $fontColor !important;
    }
    .user-move {
      background: $dark2;
    }
    .best-move {
      background: $dark3;
    }
  }

  .move-list {
    .move-list-rounds {
      .grid-row {
        .round-nr,
        .move {
          color: $fontColor;
        }
      }
      .round-human-cp-score .cp-score {
        background-color: $dark35;
      }
    }
  }
  .puzzle-results {
    .result {
      background-color: $dark2 !important;
    }
  }
  .range-label-min,
  .range-label-max {
    color: darken($color-light, 36%) !important;
  }
  .puzzle-themes {
    .tag {
      background: $dark1 !important;
      color: darken($color-light, 36%) !important;
      &.active {
        color: darken($color-light, 16%) !important;
      }
    }
  }
}
