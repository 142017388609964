@import "../../styles/vars.scss";
@import "../../styles/mixins.scss"; 
@import "../../styles/icons.scss";
  
.player-profile {  
  position: relative; 
  width: 100%; 
  height: 66px;  
  @include respond-to("sm") { 
    height: 76px; 
  } 
  @include respond-to("xxl") { 
    height: 100px;
  } 
  &.big{
    height: calc(63% - 20px); 
    .avatar{
      display: block;
      width: 63%; 
      padding-top: calc(63% - 18px);
      margin: 0 auto 1vh auto; 
      box-shadow: $box-shadow-12;
      border: 10px solid #fff;  
    }
  }
  &.black {
    .avatar {
      background-color: #131313;
    }
  }
  &.white {
    .avatar {
      background-color: $primary-color-5;
    }
  } 
  .avatar {
    display: inline-block; 
    border-radius: 100%; 
    border: 6px solid #fff;
    width: 66px;
    height: 66px; 
    background-size:102%; 
    box-shadow: $box-shadow-1;
    background-position:-100% -1%, cover; 
    background-repeat:no-repeat;  
    @include respond-to("sm") { 
      width: 76px;
      height: 76px; 
    } 
    @include respond-to("md") {
      border: 7px solid #fff; 
    } 
    @include respond-to("xxl") {
      border: 8px solid #fff;
      width: 100px;
      height: 100px; 
    }  
  }
  &.editable{ 
    .avatar { 
      cursor:pointer;  
      position:relative; 
      [class*="icon-"]{
        position:absolute;
        z-index:999;
        left:75%;
        top:75%;
        width:25%;
        display:none;
        background-size:56%; 
        padding-bottom:25%;
        background-color:#fff;
        border-radius:100%;
        box-shadow:$box-shadow-12
      } 
      @media(hover: hover) and (pointer: fine) {
        &:hover{
          [class*="icon-"]{
            display:block;
          }
        }
      } 
    }
  }

  &.winner {
    .avatar {
      transform: scaleX(1); 
      border-color: lighten($highlight-color-2, 33%) !important;
    } 
    .avatar:after {
      content: "";
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      position: absolute;
      top: -6px;
      left: -6px;
      z-index: 10;
      border-radius: 100%;
      border: 6px dashed lighten($highlight-color-2, 24%);
      box-shadow: 0 0 18px lighten($highlight-color-2, 12%);
     //animation: rotate 8s linear infinite; 
     @include respond-to("md") {
        width: calc(100% + 14px);
        height: calc(100% + 14px);
        border-width: 7px;
        box-shadow: 0 0 21px lighten($highlight-color-2, 18%);
        top: -7px;
        left: -7px;
        padding-bottom: calc(100% - 14px);
      }  
      @include respond-to("xxl") {
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        border-width: 8px;
        box-shadow: 0 0 24px lighten($highlight-color-2, 18%);
        top: -8px;
        left: -8px;
        padding-bottom: calc(100% - 16px);
      }
    }
  } 
  .countdown-timer {
    position: absolute;
    left:0;
    bottom:0;  
    margin:0 18px -1px 18px; 
    @include respond-to("sm") { 
      margin:0 22px -2px 22px;  
    }
    @include respond-to("md") { 
      margin:0 22px -2px 22px;  
    }
    @include respond-to("lg") { 
      margin:0 16px -3px 16px;  
    }
    @include respond-to("xxl") { 
      margin:0 26px -3px 26px;  
    }
  }
  .title {
    position: absolute;
    top: 6px;
    left:70px;
    font-size: 10px; 
    font-weight: 700;
    white-space: nowrap;
    @include respond-to("sm") {
      top: 5px;
      left:80px;
      font-size: 12px; 
    } 
    @include respond-to("xxl") {
      top: 12px;
      left:110px;
      font-size: 14px;
    }
    .tooltip-wrapper{ 
      display:inline-block; 
      margin:0 0 0 4px;
    }
    [class*="ai-tooltip-"]{ 
      background:$color;
      color:#fff; 
      font-weight: 700; 
      border-radius: 100%;
      font-family: sans-serif;
      width:14px;
      height:14px;
      overflow:hidden;
      font-size:10px;
      line-height:10px; 
    }
    .human-elo-diff {
      display: inline-block;
      padding: 0 0 0 3px;
      position: relative;
      letter-spacing: -1px;
      font-size: inherit; 
      &.gain {
        color: $best-color; 
      }
      &.loss {
        color: $blunder-color; 
      }
    }
  } 
} 

 