@import "./vars.scss";

$buttons: (
  "1": $primary-color-1,
  "2": $highlight-color-5,
  "3": transparent,
  "4": $best-color,
  "5": $mistake-color,
  "6": $blunder-color,
  "7": $form-background-btn-color,
);

@each $nr, $col in $buttons {
  [class*="btn-#{$nr}-"] {
    background-color: $col;
    color: lighten($color-light, 12%);
    font-weight: 700;
    padding: 1em 0;
    white-space: nowrap;
    line-height: 0;
    border: 0 solid transparent;
    width: 100%;
    overflow: hidden;
    //text-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  [class*="btn-#{$nr}-"].in-active,
  [class*="btn-#{$nr}-"]:disabled {
    filter: grayscale(100%);
    opacity: 0.4;
  }

  [class*="btn-#{$nr}-"]:active,
  [class*="btn-#{$nr}-"].active {
    background-color: darken($col, 14%); 
  }

  [class*="btn-#{$nr}-"].highlight {
    animation: highlight-animate-btn-#{$nr} 1.33s infinite;
  }
  @keyframes highlight-animate-btn-#{$nr} {
    0% {
      background-color: $col;
    }
    50.0% {
      background-color: lighten($col, 9%);
    }
    100.0% {
      background-color: $col;
    }
  }
  .btn-#{$nr}-xs {
    font-size: 10px;
    border-radius: 3px;
    height: 24px;
    @if $col != transparent {
      box-shadow: 0 1px 0 0 darken($col, 10%), 0 1px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 2px 1px rgba(0, 0, 0, 0.15);
    }
  }
  .btn-#{$nr}-xs:empty {
    height: 24px;
  }
  .btn-#{$nr}-xs:active,
  .btn-#{$nr}-xs.active {
    @if $col != transparent {
      box-shadow: 0 1px 0 0 darken($col, 8%), 0 1px 0 0 rgba(0, 0, 0, 0),
        0 1px 2px rgba(0, 0, 0, 0), 0 1px 2px 1px rgba(0, 0, 0, 0.1) inset;
    }
  }
  .btn-#{$nr}-sm {
    height: 24px;
    font-size: 12px;
    border-radius: 4px;
    margin-bottom: 2px;
    @if $col != transparent {
      box-shadow: 0 2px 0 0 darken($col, 10%), 0 1px 1px -1px rgba(0, 0, 0, 0.2),
        0 2px 3px 1px rgba(0, 0, 0, 0.15);
    }
  }
  .btn-#{$nr}-sm:active,
  .btn-#{$nr}-sm.active {
    @if $col != transparent {
      box-shadow: 0 1px 0 0 darken($col, 8%), 0 1px 0 0 rgba(0, 0, 0, 0),
        0 1px 2px rgba(0, 0, 0, 0), 0 1px 2px 1px rgba(0, 0, 0, 0.1) inset,
        0 2px 0 0 darken($col, 6%);
    }
  }
  .btn-#{$nr}-md:empty {
    height: 24px;
  }
  .btn-#{$nr}-md {
    height: 28px;
    font-size: 14px;
    border-radius: 4px;
    margin-bottom: 3px;
    @if $col != transparent {
      box-shadow: 0 3px 0 0 darken($col, 10%), 0 1px 1px -1px rgba(0, 0, 0, 0.2),
      0 2px 3px 1px rgba(0, 0, 0, 0.15);
    }
  }
  .btn-#{$nr}-md:empty {
    height: 28px;
  }
 
  .btn-#{$nr}-md:active, .btn-#{$nr}-md.active {
    @if $col != transparent {
      box-shadow: 0 2px 0 0 darken($col, 8%), 0 1px 0 0 rgba(0, 0, 0, 0),
        0 1px 2px rgba(0, 0, 0, 0), 0 1px 2px 1px rgba(0, 0, 0, 0.1) inset,
        0 3px 0 0 darken($col, 6%);
    }
  }
  .btn-#{$nr}-lg {
    height: 32px;
    min-height: 2.2vmax;
    @include respond-to("xs") {
      font-size: 19px;
    }
    border-radius: 4px;
    margin-bottom: 4px;
    @if $col != transparent {
      box-shadow: 0 4px 0 0 darken($col, 10%), 0 1px 1px -1px rgba(0, 0, 0, 0.2),
        0 2px 3px 1px rgba(0, 0, 0, 0.15);
    }
  }
  .btn-#{$nr}-lg:empty {
    height: 32px;
  }
  .btn-#{$nr}-lg:active,
  .btn-#{$nr}-lg.active {
    @if $col != transparent {
      box-shadow: 0 2px 0 0 darken($col, 8%), 0 1px 0 0 rgba(0, 0, 0, 0),
        0 1px 2px rgba(0, 0, 0, 0), 0 1px 2px 1px rgba(0, 0, 0, 0.1) inset,
        0 4px 0 0 darken($col, 6%);
    }
  }
  .btn-#{$nr}-xl {
    @include respond-to("xs") {
      font-size: 24px;
    }
    @include respond-to("sm") {
      font-size: 30px;
    }
    min-height: 3.2vmax;
    border-radius: 4px;
    margin-bottom: 5px;
    @if $col != transparent {
      box-shadow: 0 5px 0 0 darken($col, 10%), 0 1px 1px -1px rgba(0, 0, 0, 0.2),
        0 2px 3px 1px rgba(0, 0, 0, 0.15);
    }
  }
  .btn-#{$nr}-xl:empty {
    height: 3.2vmax;
  }
  .btn-#{$nr}-xl:active,
  .btn-#{$nr}-xl.active {
    @if $col != transparent {
      box-shadow: 0 2px 0 0 darken($col, 8%), 0 1px 0 0 rgba(0, 0, 0, 0),
        0 1px 2px rgba(0, 0, 0, 0), 0 1px 2px 1px rgba(0, 0, 0, 0.15) inset,
        0 5px 0 0 darken($col, 6%);
    }
  }

 
}

[class*="btn-"] {
  &.btn-inline {
    width: auto;
    padding-right: 1.6vh;
    padding-left: 1.6vh;
  }
  &.btn-inline[class*="icon-"]:not(:empty) {
    background-position-x: 1.6vh;
    background-size: auto 70%;
    padding-left: 3.6vh;
  }
  &.btn-color-black {
    color: $color;
  }
}
.btn-group {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
  grid-gap: 0;
}
.btn-inline-right {
  text-align: right;
  [class*="btn-"] {
    display: inline-block;
  }
}
.btn-group-inline {
  [class*="-xs"]:empty {
    width: 40px;
  }
  [class*="-sm"]:empty {
    width: 40px;
  }
  [class*="-md"]:empty {
    width: 40px;
  }
  [class*="-lg"]:empty {
    width: 40px;
  }
  [class*="-xxl"]:empty {
    width: 40px;
  }
}
.btn-group,
.btn-group-inline {
  [class*="btn-"]:first-of-type {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }
  [class*="btn-"]:last-of-type {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }
  [class*="btn-"]:not(:first-of-type):not(:last-of-type) {
    border-radius: unset;
  }
}
