@import "../../../styles/mixins.scss";
 /* Custom properties */

$fillStar: 'FBBC05';
$fillAltStar: 'FBBC05';
$star:"%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cdefs/%3E%3Cpath fill='#{'%23' + $fillStar}' d='M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z'/%3E%3C/svg%3E";
$altStar:"%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cdefs/%3E%3Cpath fill='#{'%23' + $fillAltStar}' d='M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z'/%3E%3C/svg%3E";

.star-rating{ 
  width: 100%;
  max-width: 320px; 
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(18px, 0.8fr));
  grid-gap: 0;
  @include respond-to("sm") {
    max-width: 500px;
  }
  button {
    width:60px;
    padding-bottom:100%;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    background-position: 50% 50%;
    background-size: 80% 80%;
    background-repeat: no-repeat;
    @include respond-to("sm") {
      width:80px;
      background-size: 80% 80%;
    }
    &.on {
      background-image: url("data:image/svg+xml,#{$altStar}");
    }
    &.off {
      background-image: url("data:image/svg+xml,#{$star}");
    }
  }
}