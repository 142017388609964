@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss"; 
  
.countdown-timer { 
  font-size: 9px; 
  font-weight: bold;
  white-space: nowrap;
  color: #fff;
  min-width:30px;
  line-height:10px; 
  border-radius:3px;
  min-width:33px;
  text-align:center;
  padding:1px 2px 0 2px; 
  font-family: "Courier", monospace;
  font-variant-numeric: tabular-nums; 
  background:rgba(0,0,0,.6);
  box-shadow:$box-shadow-12, inset 0 0 12px rgba(0,0,0,1);
  border:1px solid rgba(0,0,0,1);
  @include respond-to("lg") {
    border-radius:4px; 
    font-size: 12px; 
    min-width:46px;
    padding:3px 5px 2px 5px;
  }
  &.below-10{
    background:rgba(146, 148, 10, 0.9);
    box-shadow:$box-shadow-12, inset 0 0 12px rgba(146, 148, 10, 0.9);
    border:1px solid rgb(106, 107, 18); 
}
  &.below-5{
      background:rgba(182, 0, 0, 0.9);
      box-shadow:$box-shadow-12, inset 0 0 12px rgba(182,0,0,.9);
      border:1px solid rgb(116, 10, 10);
  }
  &.timer-alert{
    animation: rippleDrop 0.4s linear;
  }
  @keyframes rippleDrop {
    100% {
      transform: scale(2);
      opacity: .8; 
    }
  }
}

 