@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/icons.scss";

.modal-bg {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  &:not(.no-bg) {
    backdrop-filter: blur(4px);
    background: linear-gradient(
      180deg,
      rgba(186, 207, 218, 0) 0%,
      rgba(19, 48, 68, 0.6888858570772058) 26%,
      rgba(19, 48, 68, 0.7888858570772058) 100%
    );
    @include respond-to("sm") {
      background: linear-gradient(
        180deg,
        rgba(19, 48, 68, 0.4888858570772058) 0%,
        rgba(19, 48, 68, 0.8429474817270658) 70%
      );
    }
  }

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: auto;
  .modal {
    position: relative;
    margin: 2vh auto 2vh auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    animation: fadeIn 400ms;
    border: 6px solid #fff;
    background: #fff;
    border-radius: $border-radius-2;
    box-shadow: $box-shadow-42;
    @include respond-to("xs") {
      width: 96%;
    }
    @include respond-to("sm") {
      border: 10px solid #fff;
      width: 66.66667%;
    }
    @include respond-to("md") {
      width: 66.66667%;
    }
    @include respond-to("lg") {
      width: 42%;
    }
    @include respond-to("xl") {
      width: 29%;
    }
    @include respond-to("xxl") {
      width: 29%;
    }
    h1 {
      font-size: 40px;
      white-space: nowrap;
      letter-spacing: -1px;
      padding: 0;
      margin-top: -10px;
      text-align: center;
      @include respond-to("lg") {
        font-size: 46px;
      }
      @include respond-to("xl") {
        font-size: 50px;
      }
      @include respond-to("xxl") {
        font-size: 67px;
      }
    }

    button.modal-close {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 100;
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 13px;
      background-color: darken($color-muted, 14%);
      background-size: 100%;
      background-repeat: no-repeat;
      box-shadow: $box-shadow-12;
      background-image: url("data:image/svg+xml, #{str-replace(map-get($icon-svg-list , close), 'lineFill', '%23f1f1f1')}");
    }
    .modal-header {
      width: 100%;
      height: calc(120px + 3vw);
      padding-top: 2vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .modal-header-content {
      position: absolute;
      left: 0;
      top: calc(120px + 3vw);
      padding: 0;
      margin-top: calc(-36px - 0.8vh);
      height: 36px;
      margin-bottom: 1vh;
      width: 100%;
      vertical-align: text-bottom;
    }
    .modal-content {
      /* let it scroll vertical*/
      overflow-y: auto;
      overflow-x: hidden;
      &.prevent-root-overflow {
        max-height: calc(100vh - 266px - 5vh);
      }
    }
    .modal-call-to-action {
      user-select: none;
      position: absolute;
      bottom: 3px;
      left: 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
      grid-gap: 5px;
    }
    &.with-call-to-action {
      padding-bottom: 64px;
      @include respond-to("sm") {
        padding-bottom: 76px;
      }
      @include respond-to("lg") {
        padding-bottom: 76px;
      }
      .modal-content {
        .modal-content-inner {
          padding: 0 1vh 1vh 1vh;
          .modal-content-inner-txt {
            font-weight: 500;
            margin-bottom: 1vh;
          }
        }
      }
    }
  }
  .modal-loading {
    position: fixed;
    z-index: 117;
    opacity: 0;
    animation: fadeIn 400ms;
    animation-delay: 300ms;
    animation-fill-mode: forwards;
    width: 100px;
    height: 100px;
    background-color: rgba(256, 256, 256, 0.1);
    border-radius: 100%;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    background-position: 50% 50%;
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,#{$preloader-white}");
    @include respond-to("lg") {
      width: 160px;
      height: 160px;
      margin-top: -80px;
      margin-left: -80px;
    }
  }
}
