@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";
@import "../../styles/icons.scss";

.modal-header-content {
  .toggle-container {
    float: right;
    margin-right: calc(2vh + 10px);
  }
}
.modal-content {
  .no-match {
    padding: 0 1.6vh 2.6vh 1.6vh;
    font-weight: bold;
    font-size: 18px;
  }
}
.page-puzzless {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
  .clear-filters {
    float: right;
    color: $color;
  }
  @media screen and (orientation: landscape) {
    @include respond-to("md") {
      grid-template-columns: calc(100vh - 130px) auto;
      grid-gap: 20px;
    }
    @include respond-to("lg") {
      margin-top: 5px;
      grid-template-columns: calc(100vh - 145px) auto;
    }
    @include respond-to("xl") {
      grid-template-columns: calc(100vh - 135px) auto;
      grid-gap: 30px;
    }
    @include respond-to("xxl") {
      margin-top: 0;
      grid-template-columns: calc(100vh - 125px) auto;
    }
  }
  .chessboard-wrp {
    width: 100%;
    .chessboard-tools {
      float: left;
      margin-top: 0;
      grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
      white-space: normal;
      border-bottom-right-radius: $border-radius-2;
      border-bottom-left-radius: $border-radius-2;
      box-shadow: $box-shadow-22;
      button.first {
        border-top-left-radius: unset;
        border-bottom-left-radius: $border-radius-2;
      }
      button.last {
        border-top-right-radius: unset;
        border-bottom-right-radius: $border-radius-2;
      }
    }
    .chessboard {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      .board-wrp {
        &.wrong-last-move {
          .square.w.last-move::before {
            background-color: rgba(lighten($blunder-color, 7%), 0.8);
          }
          .square.b.last-move::before {
            background-color: rgba(lighten($blunder-color, 18%), 0.9);
          }
        }
        &.correct-last-move,
        &.solved-last-move {
          .square.w.last-move::before {
            background-color: rgba(lighten($best-color, 7%), 0.8);
          }
          .square.b.last-move::before {
            background-color: rgba(lighten($best-color, 13%), 0.9);
          }
        }
      }
    }
  }
  .info-wrp {
    margin-top: 1.2%;
    @include respond-to("lg") {
      margin-top: 0;
    }
    .puzzle-info {
      display: flex;
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
      min-height: 42px;
      margin-top: 0.4vh;
      @include respond-to("md") {
        font-size: 22px;
        min-height: 52px;
      }
      @include respond-to("lg") {
        margin-top: 0;
        font-size: 26px;
        min-height: 56px;
      }
      text-align: center;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      &.wrong {
        background: $blunder-color;
        color: $color-light;
      }
      &.correct {
        background: $best-color;
        color: $color-light;
      }
      &.solved {
        background: $best-color;
        color: $color-light;
      }
      &.hint-used {
        background: $mistake-color;
        color: $color-light;
      }
    }
    .player-puzzle-stats {
      display: grid;
      grid-template-columns: 66px 3fr 5fr;
      grid-gap: 6px;
      padding: 0;
      border-top-left-radius: 66px;
      border-bottom-left-radius: 66px;
      @include respond-to("sm") {
        grid-gap: 10px;
        grid-template-columns: 76px 3fr 5fr;
      }
      @include respond-to("xxl") {
        grid-gap: 15px;
        grid-template-columns: 100px 3fr 5fr;
      }
      .player-info {
        padding: 6px 0;
        @include respond-to("sm") {
          padding: 8px 0;
        }
        @include respond-to("xxl") {
          padding: 15px 0;
        }
        .title {
          line-height: 14px;
          font-size: 10px;
          font-weight: 700;
          @include respond-to("sm") {
            font-size: 12px;
          }
          @include respond-to("xxl") {
            font-size: 14px;
          }
        }
        .info.games-played {
          margin-top: 4px;
          @include respond-to("sm") {
            margin-top: 5px;
          }
          @include respond-to("xxl") {
            margin-top: 8px;
          }
        }
      }
      .puzzle-results {
        padding: 6px 6px 0 0;
        @include respond-to("sm") {
          padding: 10px 10px 0 0;
        }
        @include respond-to("xxl") {
          padding: 15px 15px 0 0;
        }
        .info-box {
          display: grid;
          grid-gap: 1vh;
          grid-template-columns: 50px 1fr;
          margin-bottom: 5px;
          &:last-of-type {
            margin-bottom: none;
          }
          .info-label {
            white-space: nowrap;
            text-align: right;
            line-height: 15px;
            @include respond-to("sm") {
              padding-top: 1px;
              line-height: 16px;
            }
            @include respond-to("xxl") {
              padding-top: 2px;
              line-height: 18px;
            }
          }
          .bar {
            min-height: auto;
            padding: 0;
            height: 15px;
            @include respond-to("sm") {
              height: 16px;
            }
            @include respond-to("xxl") {
              margin-top: 2px;
              height: 18px;
            }
            overflow: hidden;
            .pct {
              height: 100%;
              border-top-left-radius: $border-radius-1;
              border-bottom-left-radius: $border-radius-1;
              &.solved {
                background-color: $best-color;
              }
              &.hint-used {
                background-color: $mistake-color;
              }
              &.failed {
                background-color: $blunder-color;
              }
            }
          }
        }
      }
    }

    .filter-wrp {
      grid-template-columns: 17fr 5fr minmax(30px, 2fr);
      grid-gap: 1vh;
      position: relative;
      @include respond-to("md") {
        margin-bottom: 6px;
      }
      label.range-label {
        margin: 2px 0 0 0;
        line-height: 11px;
        text-transform: uppercase;
        font-weight: 700;
        .level {
          font-size: 11px;
        }
        .range {
          font-size: 9px;
        }
      }
      button[class*="icon-filter"] {
        position: relative;
        .count {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -4px;
          margin-top: -6px;
          width: 26px;
          font-size: 9px;
          letter-spacing: -1px;
          height: 20px;
          line-height: 20px;
          color: $color;
          text-align: center;
        }
      }
    }
    .puzzle-stats {
      display: grid;
      grid-template-columns: 2fr 4fr 3fr 1fr;
      grid-gap: 1vh;
      margin-top: 4px;
      margin-bottom: 14px;
      @include respond-to("sm") {
        margin-top: 2px;
        margin-bottom: 14px;
      }
      @include respond-to("xxl") {
        margin-top: 0;
        margin-bottom: 16px;
      }
    }
    .puzzle-themes {
      content: "";
      display: table;
      clear: both;
      .tag {
        background-color: $none-form-bg-color;
        float: left;
        text-align: left;
        font-size: 10px;
        border-radius: $border-radius-2;
        padding: 2px 7px;
        font-weight: bold;
        color: lighten($color, 15%);
        cursor: help;
        margin-top: 1vh;
        margin-right: 4px;
        &.active {
          color: darken($color, 20%);
        }
      }
    }
  }
}
