@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";
 /* Custom properties */
 .toggle-container-label{ 
    margin: 2px 0;
    line-height:25px;
    &.disabled {
      cursor: auto;
      color:$disabled-color;
    } 
 }
 .toggle-container {
  display:inline-block;
  width: 46px; 
  cursor: pointer;
  user-select: none; 
  padding: 4px;
  height: 25px;
  position: relative;
  box-shadow:inset 1px 2px 1px rgba(0, 0, 0, 0.25); 
  border-radius: 20px;
  background-color: lighten($best-color, 10%);
  .dialog-button {
    position:absolute;
    top:0;
    left: 21px;
    height: 20px;
    width: 20px;
    font-weight: bold;
    cursor: pointer;
    background-color:#fff;   
    border-radius:100%;
    box-sizing: border-box;
    box-shadow:$box-shadow-22;
    position: absolute; 
    transition: all 0.3s ease; 
  }
  &.disabled { 
    background-color: lighten($primary-color-7, 20%);
    .dialog-button {
      left: 0;
    } 
  }
  &:disabled {
    cursor: auto;
    background-color:darken($disabled-background-color, 4%);
   
    .dialog-button { 
      cursor: not-allowed; 
      background-color:darken($disabled-background-color, 4%);
    } 
  }
}



