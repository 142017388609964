@import "../../../styles/vars.scss";

// custom styling 
$track-color: $form-background-input-color;
$track-shadow-size: 1px;
$track-shadow-blur: 2px;
$track-shadow-color: rgba(0, 0, 0, 1);
$track-height: 24px;
$track-border-color: transparent; 
$track-radius: 12px; 
$thumb-shadow-size: 2px;
$thumb-shadow-blur: 1px;
$thumb-color:$logo-color;
$thumb-radius: 10px;
$thumb-height: 20px;
$thumb-width: 20px;
// Styling Cross-Browser Compatible Range Inputs with Sass
$track-color: #eceff1 !default;
$thumb-color: #607d8b !default;

$thumb-radius: 12px !default;
$thumb-height: 26px !default;
$thumb-width: 26px !default;
$thumb-shadow-size: 1px !default;
$thumb-shadow-blur: 2px !default;
$thumb-shadow-color: rgba(0, 0, 0, 0.2) !default;
$thumb-border-width: 2px !default; 

$track-width: 100% !default;
$track-height: 8px !default;
 
$track-border-width: 2px !default;
$track-border-color: #cfd8dc !default;

$track-radius: 5px !default;
$contrast: 0% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
    0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  cursor: default;
  height: $track-height;
  transition: all 0.2s ease;
  width: $track-width;
  box-shadow:inset 1px 2px 1px rgba(0, 0, 0, 0.25); 
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  box-shadow:$box-shadow-22;
  border-radius: 100%; 
  cursor: grab;
  height: $thumb-height;
  width: $thumb-width; 
} 
.range-slider-wrp { 
  display: inline-block;
  width: 100%;
  position: relative;
  padding-bottom: 10px;  
  [class*="range-label-"] {
    position: absolute;
    font-weight: 700;
    bottom: -10px;
    font-size: 10px;
    color: darken($color-muted, 5%);
  }
  .range-label-min {
    left: 0;
  }
  .range-label-max {
    right: 0;
  }
}
[type="range"] {
  -webkit-appearance: none;
  background: transparent; 
  width: $track-width;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: lighten($track-color, $contrast);
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: lighten($track-color, $contrast);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;

    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: (
      (-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2
    );
  }

  &::-moz-range-track { 
    @include track;
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    height: $track-height / 2;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: ($thumb-height / 2) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    @include shadow(
      $track-shadow-size,
      $track-shadow-blur,
      $track-shadow-color
    );
    background: $ie-bottom-track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    @include shadow(
      $track-shadow-size,
      $track-shadow-blur,
      $track-shadow-color
    );
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: $track-height / 4;
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}
