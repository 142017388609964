$themes: (
    "blue": ('#53758b', '#d1dfe6', '#416075', '#5f88a3'), 
    "green": ('#62853e', '#d3e2c1', '#4e6d2f', '#799b56'), 
    "orange": ('#e66617', '#f0d9bc', '#b04401', '#f67b2e'), 
    "brown": ('#7c5a1b', '#dacaab', '#67470b', '#9d752b'),
    "gray": ('#646464', '#cdcdcd', '#505050', '#7b7b7b'), 
    "pink": ('#8f2e89', '#d0b2d4', '#691064', '#a73fa1') 
);
  
@each $theme, $colors in $themes {

  $darkColor: nth($colors, 1);
  $lightColor: nth($colors, 2);
  $btnHoverColor: nth($colors, 3);
  $btnHighlightColor: nth($colors, 4);

  [class*="theme-#{$theme}"] {
    .chessboard-tools{
      background-color:#{$btnHoverColor}; 
      border-top:1px solid  #{$btnHoverColor};
      [class*="btn-"]{ 
        background-color:#{$darkColor}; 
        box-shadow: 0 4px 0 0 #{$btnHoverColor}, 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 2px 3px 1px rgba(0, 0, 0, 0.15);
        &:active,
        &.active {
          background-color: #{$btnHoverColor}; 
          box-shadow:inset 0 1px 0 0 #{$btnHoverColor}, 0 1px 0 0 rgba(0, 0, 0, 0),
            0 1px 2px rgba(0, 0, 0, 0), 0 1px 2px 1px rgba(0, 0, 0, 0.1) inset !important; 
        }
        &.highlight { 
          animation: highlight-animate-btn-#{$theme} 1.33s infinite;
        }
        @keyframes highlight-animate-btn-#{$theme} { 
          0%     {background-color: #{$darkColor}}
          50.0%  {background-color:  #{$btnHighlightColor}}  
          100.0%  {background-color: #{$darkColor}}
        }
      } 
    }
    .chessboard {
      background-color: #{$darkColor};
      .board-wrp{
        .promotion-picker{
          background-color: #{$darkColor};  
        }
        background-color: #{$darkColor}; 
        .square.b {
          background-color: #{$darkColor};
         // background-image: repeating-linear-gradient(45deg, #{$lightColor}, #{$lightColor} 3px, transparent 3px, transparent 6px) 
         }
        .square.w {
           background-color: #{$lightColor};
          // background-image: repeating-linear-gradient(45deg, #{$btnHoverColor}, #{$btnHoverColor} 3px, transparent 3px, transparent 6px) 
        }  
      } 
    }
    &.preview { 
      .col-1 {
        background-color: #{$darkColor};
      }
      .col-2{
        background-color: #{$lightColor};
      }  
    }
  }  
}

.user-settings-piece-type-chessIcons{ 
  .piece[color="w"] {
    .pathPiece{
      stroke-width:2px; 
    } 
  }
  .piece[color="b"] { 
    .pathLine{
      stroke-width:2px;
      stroke: rgba(255, 255, 255, 0.6);
    }
  } 
  .player-panel, .move-list{ 
    .piece[color="w"] {
      .pathPiece{
        stroke-width:0; 
      } 
    }
    .piece[color="b"] {  
      .pathLine{
        stroke-width:3px;
        stroke: rgba(255, 255, 255, 1);
      }
      .pathLine{
        stroke-width:3px;
        stroke: rgba(255, 255, 255, 1);
      }
    } 
  }  
}
  
.user-settings-piece-type-fontAwesome{ 
  .player-panel, .move-list{ 
    .piece[color="w"] {
      .pathPiece{
        stroke-width:18px; 
      }  
    }
    .piece[color="b"] {   
      .pathPiece{
        stroke-width:10px;
        stroke: rgba(255, 255, 255, 0.88);
      } 
    } 
    .piece{
      .pathLine{
        fill:rgba(166, 166, 166, 1)!important; 
      } 
    }
  }
}