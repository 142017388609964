@import "../../styles/vars.scss";
@import "../../styles/mixins.scss"; 
@import "../../styles/icons.scss";

.player-panel {  
  width: 100%;
  display: grid; 
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
  position: relative;  
  margin-bottom:1.5%; 
  .player-white{
    .title {
      left: 70px;
      right:auto;
      @include respond-to("sm") {
        left: 78px;
      } 
      @include respond-to("xxl") {
        left: 106px;
      }
    }
    .avatar {
      background-color: $primary-color-5;
    }
  }
  .player-black {
    .title {
      left:auto;
      right: 70px;
      @include respond-to("sm") {
        right: 78px;
      } 
      @include respond-to("xxl") {
        right: 106px;
      }
    }
    .avatar {
      float: right;
      transform: scaleX(-1);
      [class*="icon-"]{
        left:0;
        transform: scaleX(-1);
      }
      background-color: darken($color, 16%);
    }
    .countdown-timer{
      left:auto;
      right:0;
    }
  }
  .winning-bar {
    display: flex; 
    align-items: center;
    z-index: 0;
    position: absolute;
    top: 54%;
    left: 60px;
    width: calc(100% - 120px);
    text-indent: 12px;
    height: 12px;
    font-size: 8px;  
    margin-top: -10px;
    background: #fff;
    overflow: hidden;
    box-shadow: $box-shadow-1; 
    color: lighten($color, 28%);  
    @include respond-to("sm") {
      top: 49%;
      left: 68px;
      text-indent: 11px;
      height: 18px;
      margin-top: -9px;
      font-size: 10px;
      line-height: 19px;
      width: calc(100% - 136px);
    } 
    @include respond-to("xxl") {
      top: 50%;
      left: 93px;
      height: 22px;
      margin-top: -11px;
      line-height: 22px;
      font-size: 12px;
      text-indent: 1.2vh;
      width: calc(100% - 186px);
    }
    .black-winning-pct-bar {
      position: absolute;
      top: 0;
      right: 0;
      justify-content:flex-end;
      display: flex;
      align-items: center;
      height: 100%; 
      background: darken($color, 16%);
      color: darken(#fff, 26%); 
      padding-right: 12px;
      transition: width 1.2s ease-in;
      min-width: 50px;
      max-width: 85%;
      @include respond-to("sm") {
        padding-right: 14px;
      }
      @include respond-to("xxl") {
        padding-right: 16px;
      }
    }
  } 
    .pieces { 
      z-index: 0;
      position: absolute;
      bottom: 0;
      white-space: nowrap;
      margin-left: 10px;
      margin-right: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      height:20px; 
      @include respond-to("sm") {
        margin-left: 0;
        margin-right: 0;
      } 
      @include respond-to("xxl") {
        bottom: 2px;
        height:26px; 
      } 
      .pieces-group {
        display: inline-block;
        margin-right: 8px; 
        .piece {
          position: relative;
          width: 17px;
          height: 17px;
          margin-left: -10px; 
          &.bq, &.wq{
            margin-left:-8px;
          }
          &.bp, &.wp{
            margin-left:-13px;
          }
        }
        @include respond-to("sm") {
          .piece {
            width:  18px;
            height: 18px;
            margin-left: -12px; 
          }
        }
        @include respond-to("xxl") {
          .piece {
            width:  20px;
            height: 20px; 
          }
        } 
      }
      .pieces-group:last-of-type {
        margin-right: 0;
      } 
      .piece-advantage {  
        position: absolute;
        top:2px; 
        font-weight:700;
        width:25px;
        @include respond-to("xxl") {
          top:7px; 
          font-size: 11px; 
        } 
        letter-spacing:-1px;
        display:block;
        width:25px;
        text-align:right;
        height:20px;
        line-height:20px; 
        font-size: 9px; 
        line-height:10px;  
      
        color: $color-muted  
      }
    }
  
    .player-white .pieces {
      left: 50px;
      transform: scaleX(-1); 
      @include respond-to("sm") {
        left: 68px;
      }
      @include respond-to("xxl") {
        left: 93px;
      }
      .piece-advantage { 
        text-align:left;
        left:-30px; 
        margin: 0 0 0 -9px; 
        transform: scaleX(-1); 
        @include respond-to("xxl") {
          margin: 0 0 0 -7px;
        }
      }
    }

    .player-black .pieces {
      right: 50px;
      text-align: right;
      @include respond-to("sm") {
        right: 68px;
      }
      @include respond-to("xxl") {
        right: 90px;
      }
      .piece-advantage {
        left:-38px; 
        margin: 0 16px 0 0;
        @include respond-to("xxl") {
          margin: 0 17px 0 0;
        }
      }
      .piece:nth-child(1) { 
        z-index:3; 
      }
      .piece:nth-child(2) {  
          z-index:2; 
      }
      .piece:nth-child(3) { 
          z-index:1;   
      }
    }
 
}
