@import "./styles/vars.scss";
@import "./styles/mixins.scss";
@import "./styles/icons.scss";
.gradient {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(darken($background-color, 10%)),
      to($background-color-2)
    )
    fixed;
}
.page-wrp {
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
  .content-wrp {
    height: auto;
    max-width: 100%;
    margin: 0 1.6%;
    @include respond-to("sm") {
      padding-top: 10px;
    }
    @include respond-to("xxl") {
      margin: 0 0 0 3.4%;
      padding: 30px 3.4% 0 18%;
    }
  }
}
.input-wrp {
  position: relative;
}
.error-msg {
  display: block;
  padding: 0 3px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: $border-radius-1;
  border-top-left-radius: $border-radius-1;
  background: $error-color;
  color: #fff;
  font-size: 10px;
}
.error-msg:empty {
  display: none;
}
.info-field {
  width: 100%;
  padding: 10px 0 0 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 30px;
  font-weight: 700;
  border-radius: $border-radius-1;
  @include respond-to("md") {
    min-height: 40px;
    line-height: 30px;
    font-size: 16px;
  }
}
.info-field:empty {
  background-position: -40% 55%;
  background-size: 60% 60%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,#{$preloader}");
}

span.aria-lable-tooltip {
  display: inline-block;
  border-radius: 100%;
  background-color: #595b60;
  width: 16px;
  height: 16px;
  background-size: 80% 80%;
  background-position: 1px 50%;
  vertical-align: middle;
  margin: -3px 0 0 6px;
  border: 2px solid #595b60;
}
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 10px;
}
*::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: $border-radius-3;
  background-color: lighten($primary-color-4, 30%);
}
*:hover::-webkit-scrollbar-thumb {
  background-color: lighten($primary-color-4, 17%);
}
